<template>
  <div class="seckill_wrapper">
    <el-tabs type="border-card" v-model="tabName" @click @tab-click="handleClick">
      <el-tab-pane label="秒杀任务" name="1">
        <div class="select_wrap">
          <div>
            <span>请输入ID：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入ID搜索 "
              v-model="TaskInfo.userId"
            ></el-input>
          </div>
          <div>
            <span>请输入名称：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入姓名搜索 "
              v-model="TaskInfo.userName"
            ></el-input>
          </div>
          <div>
            <span>请输入手机号码：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入手机号搜索 "
              v-model="TaskInfo.mobile"
            ></el-input>
          </div>
          <div>
            <span>状态：</span>
            <el-select v-model="TaskInfo.status" placeholder="请选择" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
          <el-button
            type="success"
            class="ml10"
            icon="el-icon-download"
            @click="exportTaskData"
          >导出表格</el-button>
        </div>
        <el-table class="mt20" :data="TaskList" border stripe style="width: 100%">
          <el-table-column prop="userId" label="客户ID"></el-table-column>
          <el-table-column prop="userName" label="姓名"></el-table-column>
          <el-table-column prop="userMobile" label="手机号"></el-table-column>
          <el-table-column prop="itemName" label="商品名称"></el-table-column>
          <el-table-column prop="brandName" label="所属品牌"></el-table-column>
          <el-table-column prop="helpProgress" label="助力进度"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.status==0">未完成</el-tag>
              <el-tag type="primary" v-if="scope.row.status==1">已完成</el-tag>
              <el-tag type="primary" v-if="scope.row.status==2">已支付</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="参与时间"></el-table-column>
        </el-table>
        <div class="tab-page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="TaskInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="TaskTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="秒杀订单" name="2">
        <div class="select_wrap">
          <div>
            <span>请输入订单ID：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入订单ID搜索 "
              v-model="MkOrderInfo.tradeNo"
            ></el-input>
          </div>
          <div>
            <span>请输入名称：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入姓名搜索 "
              v-model="MkOrderInfo.userName"
            ></el-input>
          </div>
          <div>
            <span>请输入手机号码：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入手机号搜索 "
              v-model="MkOrderInfo.mobile"
            ></el-input>
          </div>
          <div>
            <span>核销状态：</span>
            <el-select v-model="MkOrderInfo.status" placeholder="请选择" clearable>
              <el-option
                v-for="item in statusoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>支付状态：</span>
            <el-select v-model="MkOrderInfo.payStatus" placeholder="请选择" clearable>
              <el-option
                v-for="item in playoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage2">搜索</el-button>
          <el-button
            type="success"
            class="ml10"
            icon="el-icon-download"
            @click="exportMkOrderData"
          >导出表格</el-button>
        </div>
        <el-table class="mt20" :data="MkOrderList" border stripe style="width: 100%">
          <el-table-column prop="userId" label="客户ID"></el-table-column>
          <el-table-column prop="userName" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号"></el-table-column>
          <el-table-column prop="itemName" label="商品名称"></el-table-column>
          <el-table-column prop="itemBrandName" label="所属品牌"></el-table-column>
          <el-table-column prop="blUserName" label="归属人"></el-table-column>
          <el-table-column prop="blBrandName" label="归属人品牌"></el-table-column>
          <el-table-column prop="blShopName" label="归属人门店"></el-table-column>
          <el-table-column prop="tradeNo" label="订单ID"></el-table-column>
          <el-table-column prop="payStatus" label="支付状态">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.payStatus=='true'">已支付</el-tag>
              <el-tag type="warning" v-if="scope.row.payStatus=='false'">未支付</el-tag>
              <!-- <el-tag type="warning" >异常</el-tag> -->
            </template>
          </el-table-column>
          <el-table-column prop="payTime" label="支付时间"></el-table-column>
          <el-table-column prop="payAmount" label="支付金额"></el-table-column>
          <el-table-column prop="status" label="核销状态">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.status=='active'">未核销</el-tag>
              <el-tag type="primary" v-if="scope.row.status=='finish'">已核销</el-tag>
              <el-tag type="primary" v-if="scope.row.status=='cancel'">已退单</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="finishTime" label="核销时间"></el-table-column>
        </el-table>
        <div class="tab-page">
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="MkOrderInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="MkOrderTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import StatisticsAjax from "@/utils/https/modules/Statistics.request.js";
export default {
  name: "seckill", // 数据统计-营销数据-秒杀
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      idInput: "",
      options: [
        { value: 0, label: "未完成" },
        { value: 1, label: "已完成" },
        { value: 2, label: "已支付" },
      ],
      playoptions: [
        { value: "false", label: "未支付" },
        { value: "true", label: "已支付" },
      ],
      statusoptions: [
        { value: "active", label: "未核销" },
        { value: "finish", label: "已核销" },
      ],
      value: "",
      TaskInfo: {
        actId: 0,
        mobile: "",
        page: 1,
        size: 10,
        status: "",
        userId: "",
        userName: "",
      },
      TaskList: [],
      TaskTotal: 0,
      MkOrderInfo: {
        actId: 0,
        mobile: "",
        page: 1,
        size: 10,
        status: "",
        tradeNo: "",
        userName: "",
        payStatus: "",
      },
      MkOrderList: [],
      MkOrderTotal: 0,
      tabName: "1",
    };
  },
  computed: {},
  watch: {},
  methods: {
    //切换tab方法
    handleClick() {
      if (this.tabName == "1") {
        this.TaskInfo.page = 1;
        this.getQuerySeckillTaskList();
      } else if (this.tabName == "2") {
        this.MkOrderInfo.page = 1;
        this.getQuerySeckillMkOrderList();
      }
    },
    // 秒杀任务列表页码请求
    handleCurrentChange(val) {
      this.TaskInfo.page = val;
      this.getQuerySeckillTaskList();
    },
    searchPage() {
      this.TaskInfo.page = 1;
      this.getQuerySeckillTaskList();
    },
    // 页码请求
    handleCurrentChange2(val) {
      this.MkOrderInfo.page = val;
      this.getQuerySeckillMkOrderList();
    },
    searchPage2() {
      this.MkOrderInfo.page = 1;
      this.getQuerySeckillMkOrderList();
    },
    // 查询秒杀任务列表
    async getQuerySeckillTaskList() {
      try {
        this.TaskInfo.actId = this.activityID;
        var act = JSON.parse(JSON.stringify(this.TaskInfo));
        act.userId = Number(act.userId);
        const {
          data: { list, total },
        } = await StatisticsAjax.getQuerySeckillTaskList(act);
        this.TaskList = list;
        this.TaskTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询秒杀订单信息列表
    async getQuerySeckillMkOrderList() {
      try {
        this.MkOrderInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await StatisticsAjax.getQuerySeckillMkOrderList(this.MkOrderInfo);
        this.MkOrderList = list;
        this.MkOrderTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出秒杀任务列表
    async exportTaskData() {
      try {
        const data = await StatisticsAjax.getExportSeckillTaskOrderList(
          this.TaskInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "秒杀任务统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出秒杀订单信息列表
    async exportMkOrderData() {
      try {
        const data = await StatisticsAjax.getExportSeckillMkOrderList(
          this.MkOrderInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "秒杀订单统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQuerySeckillTaskList();
    this.getQuerySeckillMkOrderList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.seckill_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
